import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/scoreLogin',
    name: 'ScoreLogin',
    component: () => import('./view/login'),
    meta: {
      title: '成绩查询'
    }
  },
  {
    path: '/scoreList',
    name: 'ScoreList',
    component: () => import('./view/scoreList'),
    meta: {
      title: '成绩查询列表'
    }
  },
  {
    path: '/',
    name: 'TestApply',
    component: () => import('./view/testApply'),
    meta: {
      title: '报名系统'
    }
  },
  {
    path: '/scoreCheck',
    name: 'ScoreCheck',
    component: () => import('./view/scoreView/newIndex.vue'),
    meta: {
      title: '艺术考级成绩查询'
    }
  },
  {
    path: '/applySuccess',
    name: 'ApplySuccess',
    component: () => import('./view/applySuccess'),
    meta: {
      title: '报名系统'
    }
  },
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
