export default {
  namespaced: true,
  state: {
    orgId: '',
    orgName: '',
    periodId: '',
    periodName: ''
  },
  mutations: {
    SET_ORGID(state, orgId) {
      state.orgId = orgId
    },
    SET_ORGNAME(state, name) {
      state.orgName = name
    },
    SET_PERIODID(state, id) {
      state.periodId = id
    },
    SET_PERIODNAME(state, name) {
      state.periodName = name
    }
  }
}

